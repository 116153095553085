import React from 'react'
import styles from './Footer.module.css'
import Data from './Footer.json'
import FooterLinks from './footerLinks'

const Footer = () => (
  <footer className={styles.footerContainer}>
    <div className={styles.row}>
      {
        Data.map( ( { heading, links }, key ) => {
          return (
            <div className={styles.column} key={ key }>
              <div className={styles.columnWrapper}>
                <h4 className={styles.title}>{ heading }</h4>
                  {
                    ( key === 1 ) ? (
                      <div className={ styles.navLinksListWrapper }>
                        { FooterLinks( links.slice( 0, Math.floor( links.length / 2 ) ) ) }
                        { FooterLinks( links.slice( Math.floor( links.length / 2 ) ) ) }
                      </div>
                    ) : (
                      <div className={ styles.navLinksListWrapper }>
                        { FooterLinks( links ) }
                      </div>
                    )
                  }
              </div>
            </div>
          )
        } )
      }
    </div>
    <div className={styles.row}>
      <div className={styles.copyrights}>© AUTO1 Group { new Date().getFullYear() }</div>
    </div>
  </footer>
)

export default Footer
