import React from 'react'
import classNames from './HeaderSubMenu.module.css'

const HeaderDesktopSubMenu = ({ subMenu, isDesktop = false, onMouseLeaveEvent = null }) => {
  return (
    <ul
      className={isDesktop ? classNames.dropDownMenuWrapper : classNames.subMenuWrapper}
      onMouseLeave={onMouseLeaveEvent}
    >
      {subMenu.map((subItem) => {
        if (subItem.isMobile) return

        return (
          <li className={isDesktop ? classNames.dropDownItem : classNames.subMenuItemContainer} key={subItem.label}>
            <a className={isDesktop ? classNames.subItem : classNames.subMenuItem} href={subItem.href}>
              {subItem.label}
            </a>
          </li>
        )
      })}
    </ul>
  )
}
export default HeaderDesktopSubMenu
