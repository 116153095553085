import React, { Component } from 'react'
import Link from 'gatsby-link'
import { Portal } from 'react-portal'
import HeaderSubMenu from './HeaderSubMenu'
import classNames from './MobileHeaderMenu.module.css'

export default class MobileHeaderMenu extends Component {
  state = {
    isOpen: false,
    activeIndex: null
  }

  toggleIsOpen = () => {
    this.setState({
      isOpen: !this.state.isOpen,
      activeIndex: null
    })
  }

  setActiveIndex = index => {
    this.setState({
      activeIndex: this.state.activeIndex === index ? null : index
    })
  }

  render() {
    const { menuItems } = this.props
    const { isOpen, activeIndex } = this.state

    return (
      <div className={classNames.mobileMenu}>
        <button
          className={classNames.mobileMenuButton}
          onClick={this.toggleIsOpen}
        >
          <span className={classNames.navMenuIcon} />
          <span className={classNames.navMenuIcon} />
          <span className={classNames.navMenuIcon} />
        </button>
        <ul
          className={`${classNames.menuListWrapper} ${isOpen
            ? classNames.isOpen
            : ''}`}
        >
          {menuItems.map((item, index) => (
            <li className={classNames.menuLinkContainer} key={item.label}>
              {item.isRoute ? (
                <Link
                  className={classNames.menuLink}
                  onClick={() => {
                    this.setActiveIndex(index)
                  }}
                  to={item.href}
                >
                  <span className={classNames.menuLinkSelected}>
                    {item.label}
                  </span>
                </Link>
              ) : item.subMenu && item.subMenu.length > 0 ? (
                <span>
                  <span
                    className={classNames.menuLink}
                    onClick={() => {
                      this.setActiveIndex(index)
                    }}
                  >
                    {item.label}
                  </span>
                  {activeIndex === index && (
                    <HeaderSubMenu subMenu={item.subMenu} />
                  )}
                </span>
              ) : (
                <a
                  className={classNames.menuLink}
                  onClick={() => {
                    this.setActiveIndex(index)
                  }}
                  href={item.href}
                >
                  {item.label}
                </a>
              )}
            </li>
          ))}
        </ul>
        {isOpen && (
          <Portal>
            <div className={classNames.overlay} />
          </Portal>
        )}
      </div>
    )
  }
}
