import React from 'react'
import DesktopHeaderMenu from './DesktopHeaderMenu'
import MobileHeaderMenu from './MobileHeaderMenu'
import classNames from './Header.module.css'
import logo from '../img/logo-a-1-group.svg'
import { menuItems } from '../helpers'
import DeviceProvider from '../containers/DeviceProvider'

export default () => (
  <DeviceProvider
    render={isDesktop => (
      <div className={classNames.headerContainer}>
        <div className={classNames.header}>
          <h1 className={classNames.logoContainer}>
            <a href="https://www.auto1-group.com">
              <img src={logo} alt="AUTO1 Group" />
            </a>
          </h1>

          {isDesktop ? (
            <DesktopHeaderMenu menuItems={menuItems} />
          ) : (
            <MobileHeaderMenu menuItems={menuItems} />
          )}
        </div>
      </div>
    )}
  />
)
