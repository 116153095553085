import React from 'react'
import styles from './Footer.module.css'

export default ( links ) => (
  <ul className={styles.navLinksList}>
    {
      links.map( ( { href, text, images }, key ) => (
        <li className={styles.navFooterLinkContainer} key={ key }>
          <a
            href={ href }
            className={styles.navFooterLink}
          >
            { images.map( ( { src, alt }, key ) => (
              <img key={ key } src={ src } alt={ alt }/>
            ) ) }
            { text }
          </a>
        </li>
      ) )
    }
  </ul>
)
