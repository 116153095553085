import { Component } from 'react'

export default class DeviceProvider extends Component {
  state = {
    isDesktop: typeof window !== 'undefined' ? window.innerWidth > 767 : true
  }

  setIsMobile = () => {
    if (typeof window !== 'undefined') {
      if (window.innerWidth > 767 && !this.state.isDesktop) {
        this.setState({ isDesktop: true })
      }

      if (window.innerWidth <= 767 && this.state.isDesktop) {
        this.setState({ isDesktop: false })
      }
    }
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', this.setIsMobile)
    }
  }

  componentWillUnmount() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.setIsMobile)
    }
  }

  render() {
    return this.props.render(this.state.isDesktop)
  }
}
