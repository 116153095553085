import React, { Component } from 'react'
import Link from 'gatsby-link'
import HeaderDesktopSubMenu from './HeaderDesktopSubMenu'
import classNames from './DesktopHeaderMenu.module.css'

export default class DesktopHeaderMenu extends Component {
  state = {
    activeIndex: null,
  }

  showDropDown = (index) => {
    this.setState({ activeIndex: index })
  }
  hideDropDown = () => {
    this.setState({ activeIndex: null })
  }

  render() {
    const { menuItems } = this.props

    return (
      <div role="menu" onMouseLeave={this.hideDropDown} tabIndex={0}>
        <ul className={classNames.menu}>
          {menuItems.map((item, index) => {
            return (
              <li
                className={classNames.menuLinkContainer}
                key={item.label}
                onMouseEnter={() => {
                  this.showDropDown(index)
                }}
              >
                {item.isRoute ? (
                  <Link to={item.href} className={classNames.menuLinkSelected}>
                    {item.label}
                  </Link>
                ) : item.subMenu && item.subMenu.length > 0 ? (
                  <span>
                    <a className={classNames.menuLink} href={item.href}>
                      {item.label}
                    </a>
                    {this.state.activeIndex === index && (
                      <HeaderDesktopSubMenu subMenu={item.subMenu} isDesktop onMouseLeaveEvent={this.hideDropDown} />
                    )}
                  </span>
                ) : (
                  <a href={item.href} className={classNames.menuLink}>
                    {item.label}
                  </a>
                )}
              </li>
            )
          })}
        </ul>
      </div>
    )
  }
}
