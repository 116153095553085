import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { StaticQuery, graphql } from 'gatsby'
import Header from "../components/Header";
import Footer from "../components/Footer";
import apple57 from "../img/favicons/apple-touch-icon-57x57.png";
import apple60 from "../img/favicons/apple-touch-icon-60x60.png";
import apple72 from "../img/favicons/apple-touch-icon-72x72.png";
import apple76 from "../img/favicons/apple-touch-icon-76x76.png";
import apple114 from "../img/favicons/apple-touch-icon-114x114.png";
import apple120 from "../img/favicons/apple-touch-icon-120x120.png";
import apple144 from "../img/favicons/apple-touch-icon-144x144.png";
import apple152 from "../img/favicons/apple-touch-icon-152x152.png";
import apple180 from "../img/favicons/apple-touch-icon-180x180.png";
import favicon from "../img/favicons/favicon.ico";
import favicon16 from "../img/favicons/favicon-16x16.png";
import favicon32 from "../img/favicons/favicon-32x32.png";
import favicon96 from "../img/favicons/favicon-96x96.png";
import android192 from "../img/favicons/android-chrome-192x192.png";

import "./layout.css";
import "prismjs/themes/prism.css";

const TemplateWrapper = ({
  children,
  data: { site: { siteMetadata: { title, description, keywords } } }
}) => (
  <>
    <Helmet
      title={title}
      meta={[
        { name: "description", content: description },
        { name: "keywords", content: keywords },
        { "http-equiv": "Content-Language", content: "en" },
        { name: "google", content: "notranslate" }
      ]}
    >
      <html lang="en" class="notranslate" />
      <link rel="apple-touch-icon" sizes="57x57" href={apple57} />
      <link rel="apple-touch-icon" sizes="60x60" href={apple60} />
      <link rel="apple-touch-icon" sizes="72x72" href={apple72} />
      <link rel="apple-touch-icon" sizes="76x76" href={apple76} />
      <link rel="apple-touch-icon" sizes="114x114" href={apple114} />
      <link rel="apple-touch-icon" sizes="120x120" href={apple120} />
      <link rel="apple-touch-icon" sizes="144x144" href={apple144} />
      <link rel="apple-touch-icon" sizes="152x152" href={apple152} />
      <link rel="apple-touch-icon" sizes="180x180" href={apple180} />
      <link rel="icon" type="image/png" sizes="16x16" href={favicon16} />
      <link rel="icon" type="image/png" sizes="32x32" href={favicon32} />
      <link rel="icon" type="image/png" sizes="96x96" href={favicon96} />
      <link rel="icon" type="image/png" sizes="192x192" href={android192} />
      <link rel="shortcut icon" href={favicon} />
    </Helmet>
    <Header />
    {children}
    <Footer />
  </>
);

TemplateWrapper.propTypes = {
  children: PropTypes.func
};

export default ({ children }) => (
  <StaticQuery
    query={graphql`
      query LayoutQuery {
        site {
          siteMetadata {
            title
            description
            keywords
          }
        }
      }
    `}
    render={ data => TemplateWrapper( { children, data } ) }
  />
)
