import React from 'react'
import classNames from './HeaderSubMenu.module.css'

export default ({ subMenu, isDesktop = false, onMouseLeaveEvent = null }) => (
  <ul
    className={
      isDesktop ? classNames.dropDownMenuWrapper : classNames.subMenuWrapper
    }
    onMouseLeave={onMouseLeaveEvent}
  >
    {subMenu.map(subItem => (
      <li
        className={
          isDesktop ? classNames.dropDownItem : classNames.subMenuItemContainer
        }
        key={subItem.label}
      >
        <a
          className={isDesktop ? classNames.subItem : classNames.subMenuItem}
          href={subItem.href}
        >
          {subItem.label}
        </a>
      </li>
    ))}
  </ul>
)
